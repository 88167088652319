import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"


const SecondPage = () => (
  <Layout>
    <SEO title="Terms Conditions?" />
    <section className="card">
      <div className="legalcontent">
        <h1>Terms and Conditions</h1>
        <h3>1. Terms</h3>
        <br/>
        <p>By accessing our app, Soundbite, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing Soundbite. The materials contained in Soundbite are protected by applicable copyright and trademark law.</p>
        <br/>
        <h3>2. Soundbite Plus</h3>
        <br/>
        <p>You may have access to certain features and functionality within Soundbite for free. In addition, you may choose to purchase a paid subscription service ("Soundbite Plus")  through a third party such as the Apple App Store or the Google Play Store. When you purchase a subscription through a third party, you are subject to the terms and conditions of that third party.</p>
        <br/>
        <p>By purchasing this subscription, you'll have access to any incoming different and additional features. You will also authorize us the third party to automatically charge your payment method monthly at $0.99 or annually at $10 (other currencies will be at this same price point or of a similar value), plus any applicable taxes and fees. Your subscription will automatically renew at the end of the applicable subscription period, unless you cancel.</p>
        <br/>
        <p>You may only cancel through the third party, such as the Apple App Store, or Google Play Store. Your cancellation will be effective on the first day immediately following the end of the current subscription period for which you have paid. You will continue to have access to the paid subscription ("Soundbite Plus") until the end of your current subscription period and then your paid subscription ("Soundbite Plus") will automatically end. All payments are non-refundable unless stated otherwise in writing.</p>
        <br/>
        <h3>3. Use License</h3>
        <br/>
        <ol type="a">
          <li>Permission is granted to temporarily download one copy of Soundbite per device for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
          <ol type="i">
              <li>modify or copy the materials;</li>
              <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
              <li>attempt to decompile or reverse engineer any software contained in Soundbite;</li>
              <li>remove any copyright or other proprietary notations from the materials; or</li>
              <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
          </ol>
            </li>
          <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Darn Good at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
        </ol>      
        <h3>4. Disclaimer</h3>
        <br/>
        <ol type="a">
          <li>The materials within Soundbite are provided on an 'as is' basis. Darn Good makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
          <li>Further, Darn Good does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to Soundbite.</li>
        </ol>
        <br/>
        <h3>5. Limitations</h3>
        <br/>
        <p>In no event shall Darn Good or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use Soundbite, even if Darn Good or a Darn Good authorised representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
        <br/>
        <h3>6. Accuracy of materials</h3>
        <br/>
        <p>The materials appearing in Soundbite could include technical, typographical, or photographic errors. Darn Good does not warrant that any of the materials on Soundbite are accurate, complete or current. Darn Good may make changes to the materials contained in Soundbite at any time without notice. However Darn Good does not make any commitment to update the materials.</p>
        <br/>
        <h3>7. Links</h3>
        <br/>
        <p>Darn Good has not reviewed all of the sites linked to its app and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Darn Good of the site. Use of any such linked website is at the user's own risk.</p>
        <br/>
        <h3>8. Modifications</h3>
        <br/>
        <p>Darn Good may revise these terms of service for its app at any time without notice. By using Soundbite you are agreeing to be bound by the then current version of these terms of service.</p>
        <br/>
        <h3>9. Governing Law</h3>
        <br/>
        <p>These terms and conditions are governed by and construed in accordance with the laws of Malta and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
        <br/>        
      </div>
    </section>
  </Layout>
)

export default SecondPage
